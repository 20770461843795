<template>
  <v-dialog v-model="localDialog" max-width="80%">
    <v-card color="white">
      <v-toolbar dense transparent elevation="0">
        <v-toolbar-title>Comments</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="updateDialog(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <comment-list :type="type" :typeId="typeId"></comment-list>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    typeId: {
      type: [String, Number],
      required: true
    }
  },
  components: {
    CommentList: () => import("@/components/comments/List")
  },
  computed: {
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog-update", value);
      }
    }
  },
  created() {},
  methods: {
    updateDialog(dialog) {
      this.localDialog = dialog;
    },
    cancelDialog() {
      this.$emit("dialog-cancel", true);
    }
  }
};
</script>
